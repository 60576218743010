import { OrgCoupon, OrgId, OrgInvoiceId, OrgInvoiceTypes, OrgInvoice__Manual, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function playerBundleNote__client__getPlayerBundleNotesForOrgSubscription(p: {
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.PlayerBundleNote.querySubscription({
      where: [
        {
          playerBundleId: ["==", p.playerBundleId]
        },
        {
          orgId: ["==", p.orgId]
        }
      ]
    })
  );
}

// i18n certified - complete
