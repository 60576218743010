import {
  AccountId,
  OrgCoupon,
  OrgId,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  PlayerBundleId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundleNote__client__addNote(p: {
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
  note: string;
  accountId: AccountId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.PlayerBundleNote.add({
    doc: {
      appliedByAccountId: p.accountId,
      createdAtMS: Date.now(),
      id: h.PlayerBundleNote.generateId(),
      note: p.note,
      orgId: p.orgId,
      playerBundleId: p.playerBundleId
    }
  });
}

// i18n certified - complete
