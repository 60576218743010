import {
  AccountId,
  OrgCoupon,
  OrgId,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  PlayerBundleId,
  PlayerBundleNoteId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundleNote__client__deleteNote(p: { playerBundleNoteId: PlayerBundleNoteId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.PlayerBundleNote.delete({
    id: p.playerBundleNoteId
  });
}

// i18n certified - complete
